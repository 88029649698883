































































import Vue from 'vue';
import { IUserService } from '../../../shared/src/services/IUserService';

export default Vue.extend({
  data: () => ({
    loading: false,
    valid: false,
    invalidLogin: false,
    email: '',
    password: '',
    emailRules: [
        (v: any) => !!v || 'Email is required',
    ],
  }),
  inject: {
    userService: 'userService'
  },
  watch: {
    password() {
      this.invalidLogin = false;
    }
  },
  methods: {
      login(): void {
        const loginForm: any = this.$refs.loginform;

        if (loginForm.validate()) {
          this.loading = true;
          ((this as any).userService as IUserService).signIn(this.email, this.password).then((loggedIn: boolean) => {
              if (loggedIn) {
                this.$router.push('/');
                this.loading = false;
              } else {
                this.invalidLogin = true;
                loginForm.validate();
                this.loading = false;
              }
            });
        }
      },
      isPasswordValid(value: string): true | string {
        if (value.length === 0) {
          return 'Password is required';
        }

        if (this.invalidLogin) {
          return 'Invalid password';
        }

        return true;
      }
  }
});
